import React from "react";
import { styled } from "../stitches.config";
import Link from "../components/Link";
import { bodyLarge } from "./commonStyles/Fonts";
import { ReactComponent as Arrow } from "../redesign/assets/icons/arrow-right.svg?react";

interface CategoriesTabsProps {
  tabs: ITags[];
  currentTab: number;
  isMore?: boolean;
  onSelectTab?: (index: number) => void;
  setCategoryData?: (data: any) => void;
  isMobile?: boolean
  sectionLink?: string;
  headerTitle?: string;
  style?: string;
}
interface ITags {
  name: string;
  feed?: string | null;
}

const CategoriesTabs: React.FC<CategoriesTabsProps> = ({
  tabs,
  currentTab,
  isMore,
  onSelectTab,
  setCategoryData,
  isMobile,
  sectionLink,
  headerTitle,
  style,
  ...props
}) => {
  // const [activeUrl, setActiveUrl] = React.useState<string | null>(null);
  const [activeIndex, setActiveIndex] = React.useState(0);

  const handleClick = (tags: ITags, idx: number) => {
    if (tags.feed) {
      fetchFilteredData(tags.feed);
    } else {
      setCategoryData && setCategoryData(null);
    }
    setActiveIndex(idx);
    onSelectTab && onSelectTab(idx);
  };

  const fetchFilteredData = (url: string) => {
    fetch(`${window.API_HOST}${url}`)
      .then((res) => res.json())
      .then((data) => setCategoryData && setCategoryData(data));
  };

  return (
    <Root>
      <StyledCategoriesTabs className={"style--" + style}>
        {tabs.map((tags, index) => (
          <StyledCategoriesTabsButton
            onClick={() => handleClick(tags, index)}
            active={activeIndex === index}
            className={
              currentTab === index ? `current ${bodyLarge()}` : bodyLarge()
            }
            data-analytics-name={"categories-" + headerTitle + "-tag-nr-" + index + "-" + tags.name}
          >
            {tags.name}
          </StyledCategoriesTabsButton>
        ))}
      </StyledCategoriesTabs>
      {isMore && !isMobile && sectionLink && (
        <Link to={sectionLink}>
          {" "}
          <More>
            {" "}
            Więcej
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.67387 2.00977L13.6638 6.99972L8.67387 11.9897L7.68392 10.9997L10.9837 7.69992L0.408142 7.69992V6.29992L10.9841 6.29992L7.68392 2.99972L8.67387 2.00977ZM0.583142 7.52492L11.4062 7.52492L7.93141 10.9997L8.67387 11.7422L8.67397 11.7421L7.93158 10.9997L11.4064 7.52489H0.583313L0.583313 6.47492H0.583142V7.52492ZM7.93141 2.99972L11.4066 6.47489H11.4068L7.93158 2.99969L8.67394 2.25732L8.67387 2.25725L7.93141 2.99972Z" />
            </svg>
          </More>{" "}
        </Link>
      )}
    </Root>
  );
};

const Root = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "baseline",
  marginTop: "0",
  "@bp4": {
    marginTop: "-22px",
  },

  "& a:hover, & a:active": {
    textDecoration: "none",
  },
});
const StyledCategoriesTabs = styled("div", {
  width: "100%",
  display: "inline-block",
  whiteSpace: "nowrap",
  overflowY: "hidden",
  overflowX: "scroll",
  scrollbarWidth: "none",
  paddingBottom: "24px",
  "@bp4": {
    paddingBottom: "40px"
  },
  "&::-webkit-scrollbar": {
    width: "0px",
  },
  "&.style--with-background": {
    "& button": {
      color: "$grayscale0Fixed",
    }
  }
});

// const StyledCategoriesTabsButton = styled("button", {
//   position: "relative",
//   background: "none",
//   borderWidth: "0px",
//   padding: 0,
//   paddingBottom: '4px',
//   marginRight: "14px",
//   "@bp4": {
//     marginRight: "22px",
//   },
//   "&.current:after": {
//       content: '',
//       position: 'absolute',
//       bottom: '0px',
//       left: '50%',
//       transform: 'translateX(-50%)',
//       width: '4px',
//       height: '4px',
//       backgroundColor: '$natematPrimary',
//       borderRadius: '50%'
//   },
// });

const StyledCategoriesTabsButton = styled("button", {
  color: "$grayscale100",
  background: "none",
  borderWidth: "0px",
  padding: "6px",
  marginRight: "14px",
  position: "relative",
  variants: {
    active: {
      true: {
        "&::after": {
          content: "",
          position: "absolute",
          left: "50%",
          bottom: "0",
          transform: "translateX(-50%)",
          width: "4px",
          height: "4px",
          backgroundColor: "$primary",
          borderRadius: "50%",
        },
      },
    },
  },
  "@bp4": {
    marginRight: "22px",
  },
});

const More = styled("p", {
  color: "$primary",
  display: "flex",
  fontWeight: "600",
  lineHeight: "24px",
  fontSize: "16px",
  alignItems: "center",
  "& svg": {
    marginLeft: "6px",
    position: "relative",
    top: "2px",
    "& path": {
      fill: "$primary",
    }
  },
});

export default CategoriesTabs;
